import { motion } from 'framer-motion';
import React from 'react';
import cardOne from '../../assets/images/mastertechwho/card-one.webp';
import cardThree from '../../assets/images/mastertechwho/card-three.webp';
import cardTwo from '../../assets/images/mastertechwho/card-two.webp';
import leftTirinha from '../../assets/images/mastertechwho/left-tirinha.webp';
import rightPunk from '../../assets/images/mastertechwho/right-punk.webp';
import rightTirinha from '../../assets/images/mastertechwho/right-tirinha.webp';
import './MastertechWho.scss';
import { useNavigate } from 'react-router-dom';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
};

const MastertechWho = () => {
  const navigate = useNavigate();
  return (
    <>
      <motion.div className="MastertechWho">
        <motion.div
          className="container-mastertec-who"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={staggerContainer}
        >
          <motion.div className="left" variants={fadeIn}>
            <div>
              <h1>MASTERTECH WHO?</h1>
              <h2>
                educação circular <br /> em <strong>todo lugar.</strong>
              </h2>
            </div>
            <motion.div className="content" variants={fadeIn}>
              <p>
                Aqui não tem papo furado de "trabalho em equipe". A gente joga os times direto na ação, com atividades
                que provocam, sacodem e fazem todo mundo sair da zona de conforto. É sobre se conectar de verdade,
                resolver tretas criativas e transformar ideias em coisa grande. Menos blá-blá-blá, mais mão na massa. Se
                é pra construir time, que seja um time f*da!
              </p>
              <motion.img src={leftTirinha} alt="leftTirinha" variants={fadeIn} />
              <p>
                Aqui não tem papo furado de "trabalho em equipe". A gente joga os times direto na ação, com atividades
                que provocam, sacodem e fazem todo mundo sair da zona de conforto. É sobre se conectar de verdade,
                resolver tretas criativas e transformar ideias em coisa grande. Menos blá-blá-blá, mais mão na massa. Se
                é pra construir time, que seja um time f*da!
              </p>
            </motion.div>
          </motion.div>
          <motion.div className="right" variants={fadeIn}>
            <motion.div className="content" variants={fadeIn}>
              <motion.img src={rightTirinha} alt="rightTirinha" />
              <p>
                Aqui não tem papo furado de "trabalho em equipe". A gente joga os times direto na ação, com atividades
                que provocam, sacodem e fazem todo mundo sair da zona de conforto. É sobre se conectar de verdade,
                resolver tretas criativas e transformar ideias em coisa grande. Menos blá-blá-blá, mais mão na massa. Se
                é pra construir time, que seja um time f*da!
              </p>
              <motion.div className="left-image" variants={fadeIn}>
                <motion.img src={rightPunk} alt="rightPunk" />
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>

      <motion.div
        className="HouseKids"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={staggerContainer}
      >
        <div className="container-housekids">
          <motion.h4 variants={fadeIn}>CRIAS DA CASA</motion.h4>
          <motion.div className="content" variants={staggerContainer}>
            <motion.div className="card-housekids" variants={fadeIn}>
              <div className="header-card">
                <p className="title">SOMAS</p>
                <p className="subtitle">A nossa ONG</p>
              </div>
              <motion.img src={cardOne} alt="cardOne" variants={fadeIn} />
              <button>
                SAIBA MAIS <span className="ph--arrow-right-bold" />
              </button>
            </motion.div>
            <motion.div className="card-housekids" variants={fadeIn}>
              <div className="header-card">
                <p className="title">HEY IA</p>
                <p className="subtitle">O nosso livro</p>
              </div>
              <motion.img src={cardTwo} alt="cardTwo" variants={fadeIn} />
              <button>
                SAIBA MAIS <span className="ph--arrow-right-bold" />
              </button>
            </motion.div>
            <motion.div className="card-housekids" variants={fadeIn} onClick={() => navigate('/case/20')}>
              <div className="header-card">
                <p className="title">Computador circular</p>
                <p className="subtitle">O nosso jeito de fazer educação</p>
              </div>
              <motion.img src={cardThree} alt="cardThree" variants={fadeIn} />
              <button>
                SAIBA MAIS <span className="ph--arrow-right-bold" />
              </button>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default MastertechWho;

import amLeftOne from "../../assets/images/cases/a-m/leftOne.png";
import amLeftTwo from "../../assets/images/cases/a-m/leftTwo.png";
import amRightOne from "../../assets/images/cases/a-m/rightOne.png";
import amRightTwo from "../../assets/images/cases/a-m/rightTwo.png";

import heinekenLeftOne from "../../assets/images/cases/heineken/leftOne.png";
import heinekenLeftTwo from "../../assets/images/cases/heineken/leftTwo.png";
import heinekenRightOne from "../../assets/images/cases/heineken/rightOne.png";
import heinekenRightTwo from "../../assets/images/cases/heineken/rightTwo.png";

import globoLeftOne from "../../assets/images/cases/desafio-led/leftOne.png";
import globoLeftTwo from "../../assets/images/cases/desafio-led/leftTwo.png";
import globoRightOne from "../../assets/images/cases/desafio-led/rightOne.png";
import globoRightTwo from "../../assets/images/cases/desafio-led/rightTwo.png";

import bayerRightOne from "../../assets/images/cases/bayer/rightOne.png";

import pcLeftOne from "../../assets/images/cases/computador-circular/leftOne.png";
import pcRightOne from "../../assets/images/cases/computador-circular/rightOne.png";

// images recommendation
import impulso from "../../assets/images/cases/recommendations/programa-impulso.png";
import banqueSe from "../../assets/images/team-buildings/recommendations/banque-se.webp";
import led from "../../assets/images/cases/recommendations/led.png";
import am from "../../assets/images/cases/recommendations/a-m.png";

export const caseListDetails = [
  {
    id: 1,
    title: "A&M",
    description: "six sigma, design thinking <br/> e mindset ágil",
    left: {
      textOne: "A Alvarez & Marsal, líder global em gerenciamento de turnaround, reestruturação corporativa e melhoria de desempenho operacional, se uniu a Mastertech para aprimorar a atuação das suas lideranças e equipes através da construção de um mindset propositivo e digital, especialmente orientado para a inovação de processos e serviços.",
      imageOne: amLeftOne,
      textTwo: "Na primeira delas, o Yellow Belt, delimitamos o espaço de trabalho e seus gargalos, nivelamos o background das pessoas participantes e dedicamos tempo de discussão para ideações e co-criações; na sequência, dentro do módulo Red Belt, partimos para a prototipação, entregando todo o ferramental necessário para estruturar modelagens exequíveis, alinhadas com as dores identificadas anteriormente; e, fechando o nosso ciclo educacional, rodamos o Black Belt, voltado exclusivamente para a governança dos recursos investidos nos projetos que ganharam corpo durante a nossa jornada formativa.",
      imageTwo: amLeftTwo,
    },
    right: {
      textOne: "Para tanto, nos baseamos na metodologia Six Sigma para desenhar 3 trilhas com 8 aulas de 2 horas que, curtas, progressivas e gamificadas, capacitaram times e gestores(as) com conteúdos sobre tecnologias emergentes, Design Thinking, Cultura Ágil e Filosofia Lean.",
      imageOne: amRightOne,
      imageTwo: amRightTwo,
      textTwo: "Ao longo de 2 anos, formamos 8 turmas, atendendo a mais de 100 colaboradores(as) e gestores(as), e embora findada a iniciativa, ela segue repercutindo no core da instituição, pois na essência no nosso programa também estava a metodologia “train the trainers”, que, como as abelhas na natureza, poliniza e multiplica o conhecimento, maximizando os resultados da educação corporativa."
    },
    recommendation: [
      {
        title: "BANQUE-SE",
        description: "Uma vivência sentimental-cognitiva",
        image: banqueSe,
        id: 3
      },
      {
        title: "A&M | Programa IMPULSO",
        description: "Educação empreendedora e impacto social",
        image: impulso,
        id: 2
      },
    ]
  },
  {
    id: 2,
    title: "HEINEKEN",
    description: "cultura de dados <br/> e data driven mindset",
    left: {
      textOne: "Depois de se tornar a maior cervejaria do Brasil, a HEINEKEN decidiu se tornar a cervejaria mais conectada do Brasil, e para dar um gás no seu processo de transformação digital, recorreu à expertise da Mastertech.",
      imageOne: heinekenLeftOne,
      textTwo: "Lá se vão 3 anos e nessa jornada educativa já criamos conteúdo para podcasts, cursos, palestras e também para vivências remotas e presenciais. Tudo personalizado, em sintonia tanto com a cultura da empresa quanto com as tendências e inovações do mercado.",
      imageTwo: heinekenLeftTwo,
    },
    right: {
      textOne: "O nosso foco, desde o início dessa parceria, foi fomentar o mindset data-driven para estruturar uma operação mais eficaz, capaz de obter valiosos insights para a tomada de decisões estratégicas, bem como para a melhoria contínua de seus processos e serviços, sejam eles internos ou externos, em suas ações B2B e B2C.",
      imageOne: heinekenRightOne,
      imageTwo: heinekenRightTwo,
      textTwo: "Mesa de Bar, Fermentando Conhecimento e Up to Data dão nome a alguns dos projetos desenvolvidos até aqui e que, cada qual com seu formato, densidade e intenção, exploraram as tecnologias emergentes, desbravaram o universo da análise de dados e mergulharam na computação em nuvem."
    },
    recommendation: [
      {
        title: "BANQUE-SE",
        description: "Uma vivência sentimental-cognitiva",
        image: banqueSe,
        id: 3
      },
      {
        title: "A&M | Programa IMPULSO",
        description: "Educação empreendedora e impacto social",
        image: impulso,
        id: 2
      },
    ]
  },
  {
    id: 3,
    title: "GLOBO",
    description: "desafio LED <br/> me dá uma luz aí",
    left: {
      textOne: "O Desafio LED - Me dá uma luz aí! começou em 2022, quando a Mastertech foi convidada pela Fundação Roberto Marinho para estruturar e executar essa disputa nacional, uma das frentes do Movimento LED - Luz na Educação, que hoje contabiliza três exitosas edições - e caminha, a passos largos, para mais uma, em 2025!",
      imageOne: globoLeftOne,
      textTwo: "Selecionadas as propostas mais alinhados com esses objetivos, partimos para uma sequência de oficinas coletivas e colaborativa onde ensinamos técnicas de Design Thinking para transformá-las em projetos estruturados, os quais, findada esta etapa, passam por mentorias individuais, dedicadas ao aprimoramento dos mesmos e preparação dos/as proponentes para o pitch que elege as 5 iniciativas vencedoras, agraciadas com prêmios em dinheiro para que possam prosperar.",
      imageTwo: globoLeftTwo,
    },
    right: {
      textOne: "Em cada uma delas, como critério de seleção, recorremos a diferentes perguntas sobre obstáculos e oportunidades percebidas na jornada acadêmica dos/as estudantes para, a partir das suas respostas, mapear soluções educacionais exequíveis e escaláveis, capazes de contribuir para tanto para a equidade no acesso ao ensino de qualidade quanto para a sustentação de uma vida estudantil continuada.",
      imageOne: globoRightOne,
      imageTwo: globoRightTwo,
      textTwo: "As experiências que vivemos através do Desafio LED - Me dá uma luz aí!, além de transformadoras, nos enchem de esperança e materializam a nossa essência como escola e o nosso ideal como cidadãos ao multiplicar olhares e somar braços e corações em prol da educação, que entendemos ser a ferramenta mais efetiva para impulsionar a mobilidade social e melhorar a realidade brasileira."
    },
    recommendation: [
      {
        title: "BANQUE-SE",
        description: "Uma vivência sentimental-cognitiva",
        image: banqueSe,
        id: 3
      },
      {
        title: "A&M | Programa IMPULSO",
        description: "Educação empreendedora e impacto social",
        image: impulso,
        id: 2
      },
    ]
  },
  {
    id: 4,
    title: "BAYER",
    description: "inception: co-criação <br/> e inovação",
    left: {
      textOne: "A Bayer convidou a Mastertech para conduzir o Insight Pharma Week, evento que tinha como objetivo fomentar insights e soluções assertivas para resolver tanto desafios estratégicos quanto gaps da jornada do paciente, contribuindo, dessa forma, para o sucesso do seu plano de negócio. <br/><br/><br/> A nós, nessa jornada que nomeamos de Inception, coube construir uma experiência educacional fundamentada nos métodos ágeis e no Design Thinking para, ao longo de 9 encontros, mediante a participação de times multidisciplinares, desenvolver protótipos para compor o Integrated Brand Plan. <br/><br/><br/> Esses projetos, arquitetados através da inteligência e da organização esquemática de esforços e prioridades, foram clusterizados e posteriormente avaliados para incrementar a estratégia de marketing da farmacêutica, oferecendo vantagem competitiva ao acelerar a implementação de ações responsivas.",
    },
    right: {
      imageOne: bayerRightOne,
    },
    recommendation: [
      {
        title: "BANQUE-SE",
        description: "Uma vivência sentimental-cognitiva",
        image: banqueSe,
        id: 3
      },
      {
        title: "A&M | Programa IMPULSO",
        description: "Educação empreendedora e impacto social",
        image: impulso,
        id: 2
      },
    ]
  },
  {
    id: 20,
    title: "COMPUTADOR CIRCULAR",
    description: "a mastertech quer fazer diferente <br/> e quer fazer diferença",
    left: {
      textOne: "O Computador Circular é um projeto voltado tanto para a sustentabilidade quanto para a inclusão digital. <br/><br/> Coletamos e recuperamos periféricos obsoletos através de doações para estruturá-lo e assim criar um aparelho novinho em folha! <br/><br/><br/> Constituído por uma placa de microcontrolador e uma bateria envoltas por um invólucro quadrangular feito de MDF e acrílico, o dispositivo fica parecido com um modem. <br/><br/> Além de reduzir o lixo eletrônico evitando o descarte de componentes que ainda têm potencial de uso, o Computador Circular também estimula reflexões sobre desperdício e consumo excessivo, e promove a economia circular.",
      imageOne: pcLeftOne,
    },
    right: {
      imageOne: pcRightOne,
      textTwo: "E uma vez doado para pessoas em situação de vulnerabilidade, ele viabiliza o acesso à tecnologia para que elas possam estudar, se divertir e, tão importante quanto, se desenvolver enquanto cidadãos e cidadãs digitais. <br/><br/> Conjugando princípios sustentáveis e inclusivos, o Computador Circular mostra na prática como a tecnologia pode ser utilizada com consciência e responsabilidade para a construção de uma sociedade mais justa e equilibrada. <br/><br/><br/> Para se encantar com a nossa iniciativa e contribuir com ela do jeito que der - doando, divulgando, patrocinando ou só compartilhando o seu entusiasmo mesmo - escreva para contato@somas.com.br ou acesse a nossa página no Kickante."
    },
    recommendation: [
      {
        title: "GLOBO | Desafio LED",
        description: "Me Dá Uma Luz Aí Nosso programa de empreendedorismo social",
        image: led,
        id: 3
      },
      {
        title: "A&M | Programa IMPULSO",
        description: "Educação empreendedora e impacto social",
        image: am,
        id: 2
      },
    ]
  },
]

export default caseListDetails;
import { motion } from "framer-motion";
import React from 'react';
import banqueSe from "../../assets/images/team-buildings/recommendations/banque-se.webp";
import ContactHome from '../../components/ui/ContactHome/ContactHome';
import './CaseList.scss';
import { useNavigate } from "react-router-dom";

const CaseList = () => {
  const navigate = useNavigate();
  
  const dataCases = [
    {
      title: "A&M | Belts",
      description: "Six sigma, design thinking e mindset ágil",
      image: banqueSe,
      id: 1
    },
    {
      title: "HEINEKEN | Transformação",
      description: "digital Cultura de dados e data driven mindset",
      image: banqueSe,
      id: 2
    },
    {
      title: "British Council | Fórmula 1",
      description: "Educação e STEM",
      image: banqueSe,
      id: 3
    },
    {
      title: "GLOBO | Desafio LED - Me Dá Uma Luz Aí",
      description: "Nosso programa de empreendedorismo social",
      image: banqueSe,
      id: 4
    },
    {
      title: "A&M | Programa IMPULSO",
      description: "Educação empreendedora e impacto social",
      image: banqueSe,
      id: 5
    },
    {
      title: "BAYER | Inception",
      description: "Co-criação e inovação",
      image: banqueSe,
      id: 6
    },
  ];

  return (
    <>
      <div className='CaseList'>
        <div className="container-caselist">
          <div className="header-caselist">
            <motion.p
              className="title"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 1 }}
            >
              os cases <br /> da escola.
            </motion.p>
            <motion.p
              className="description"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum, gestão de tempo, definição de
            </motion.p>
          </div>

          <div className="content-cases">
            <motion.p
              className="title"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-20px" }}
              transition={{ duration: 1 }}
            >
              CASES DE TRANSFORMAÇÃO DIGITAL
            </motion.p>

            <div className="cases">
              {dataCases.map((item) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "-100px" }}
                  transition={{ duration: 2 }}
                  onClick={() => navigate(`/case/${item.id}`)}
                >
                  <div className="case">
                    <img src={item.image} alt={item.title} />
                    <div className="content-card">
                      <p className="title">{item.title}</p>
                      <p className="description">{item.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ContactHome witdh />
    </>
  );
};

export default CaseList;

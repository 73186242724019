import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading/Loading';
import { replaceWithLineBreaks } from '../../utils/formating';
import './CaseDetails.scss';
import buildingListDetails from './caseListDetails';

const CaseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contentBuilding, setContentBuilding] = useState(null);
  const [loading, setLoading] = useState(false);

  // Simulando uma API local
  const fetchBuildingDetails = async (id) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const result = buildingListDetails.find((building) => building.id === parseInt(id));
      setTimeout(() => {
        if (result) {
          resolve(result);
          setLoading(false);
        } else {
          setLoading(false);
          reject(new Error("Detalhes do building não encontrados."));
        }
      }, 500);
    });
  };

  useEffect(() => {
    const getBuildingDetails = async () => {
      try {
        const result = await fetchBuildingDetails(id);
        setContentBuilding(result);
      } catch (error) {
        console.error(error.message);
        navigate("/team-buildings");
      }
    };

    getBuildingDetails();
  }, [id, navigate]);

  // Animações
  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1.4, ease: "easeInOut" },
    },
  };

  return (
    <>
      <div className='CaseDetails'>
        <div className="container-case-details">
          {/* Animação para o cabeçalho */}
          <motion.div
            className="header-case-details"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            <p className="title">{contentBuilding?.title}</p>
            <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.description) }} />
          </motion.div>

          <motion.div
            className="content"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="left">
              <motion.p
                variants={fadeInLeft}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.left?.textOne) }}
              >
              </motion.p>
              {contentBuilding?.left?.imageOne && (
                <motion.img
                  src={contentBuilding?.left?.imageOne}
                  alt="Imagem 1"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                />
              )}
              {contentBuilding?.left?.textTwoNegrito && <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
                viewport={{ once: true }}
              >
                {contentBuilding?.left?.textTwoNegrito}
              </motion.span>}
              {contentBuilding?.left?.textTwo && <motion.p
                initial={{ opacity: 0 }}
                className='text-two'
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
                viewport={{ once: true }}
              >
                {contentBuilding?.left?.textTwo}
              </motion.p>}
              {contentBuilding?.left?.imageTwo && (
                <motion.img
                  src={contentBuilding?.left?.imageTwo}
                  alt="Imagem 2"
                  className='image-two'
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.8 }}
                  viewport={{ once: true }}
                />
              )}
            </div>

            <div className="right">
              {contentBuilding?.right?.imageOne && (
                <motion.img
                  src={contentBuilding?.right?.imageOne}
                  alt="Imagem 1"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                />
              )}
              {contentBuilding?.right?.textOne && <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
                viewport={{ once: true }}
              >
                {contentBuilding?.right?.textOne}
              </motion.p>}
              {contentBuilding?.right?.imageTwo && (
                <motion.img
                  src={contentBuilding?.right?.imageTwo}
                  alt="Imagem 2"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.8 }}
                  viewport={{ once: true }}
                />
              )}
              {contentBuilding?.right?.textTwo && <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
                viewport={{ once: true }}
                dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.right?.textTwo) }}
              />}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Seção de recomendações */}
      <motion.div
        className="you-like-section-case"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.2 }}
        viewport={{ once: true }}
      >
        <div className="container-you-like">
          <p className="title-like">Você também pode gostar</p>
          <div className="cards">
            {contentBuilding?.recommendation?.map((item, index) => (
              <motion.div
                className="card"
                key={index}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1.4 }}
                viewport={{ once: true }}
                onClick={() => {
                  navigate(`/team-buildings/${item.id}`)
                }}
              >
                <img src={item.image} alt="Imagem de um mural de prato" />
                <div className="content-card">
                  <p className="title">{item.title}</p>
                  <p className="description">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>

      <Loading active={loading} />
    </>
  );
}

export default CaseDetails;

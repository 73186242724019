import genai from "../../assets/images/trainingdetails/genai.png";
import mindset from "../../assets/images/trainingdetails/mindset.png";
import ppnp from "../../assets/images/trainingdetails/ppnp-details.png";
import transformacaoDigital from "../../assets/images/trainingdetails/transformacao-digital.png";
import upToData from "../../assets/images/trainingdetails/up-to-data.png";

export const traininglist = [
  {
    id: 1,
    minTitle: "ppnp",
    title: "programação para <br/> não programadores",
    subtitle: "uma introdução à programação <br/> para grupos não técnicos.",
    description: "Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.",
    image: ppnp,
    fundamentals: [
      {
        describe: "Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.",
      },
      {
        describe: "Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados."
      },
      {
        describe: "Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini."
      }
    ],
    duration: "3 Encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.",
    participants: "Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.",
    experience: "Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.",
  },
  {
    id: 2,
    minTitle: "GEN AI",
    title: "Gen <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AI",
    description: "Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.",
    image: genai,
    fundamentals: [
      {
        describe: "Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.",
      },
      {
        describe: "Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados."
      },
      {
        describe: "Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini."
      }
    ],
    duration: "3 Encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.",
    participants: "Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.",
    experience: "Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.",
  },

  {
    id: 3,
    minTitle: "Up to data",
    title: "up to data",
    subtitle: "do pensamento data-driven <br/> para melhores decisões.",
    description: "Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.",
    image: upToData,
    fundamentals: [
      {
        describe: "Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.",
      },
      {
        describe: "Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados."
      },
      {
        describe: "Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini."
      }
    ],
    duration: "3 Encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.",
    participants: "Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.",
    experience: "Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.",
  },
  {
    id: 4,
    minTitle: "mindset ágil",
    title: "mindset ágil",
    subtitle: "das estruturas convencionais <br/> de gestão à metodologia ágil.",
    description: "Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.",
    image: mindset,
    fundamentals: [
      {
        describe: "Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.",
      },
      {
        describe: "Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados."
      },
      {
        describe: "Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini."
      }
    ],
    duration: "3 Encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.",
    participants: "Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.",
    experience: "Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.",
  },
  {
    id: 5,
    minTitle: "transformação digital",
    title: "transformação digital",
    subtitle: "compreensão do contexto, <br/> relações sociais e pensamento crítico.",
    description: "Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.",
    image: transformacaoDigital,
    fundamentals: [
      {
        describe: "Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.",
      },
      {
        describe: "Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados."
      },
      {
        describe: "Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini."
      }
    ],
    duration: "3 Encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.",
    participants: "Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.",
    experience: "Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.",
  }
]
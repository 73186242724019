import { motion } from "framer-motion";
import React from "react";
import "./SetList.scss";
import { useNavigate } from "react-router-dom";

const SetList = () => {
  const navigate = useNavigate();
  
  const titleVariants = {
    hidden: { x: "-100px", opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.9, ease: "easeInOut" },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: "20px" },  // Alterado para animação de deslizamento vertical
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: i * 0.2,
        ease: "easeInOut",
      },
    }),
  };

  const items = [
    { title: "TRILHAS FORMATIVAS", description: "no nosso estilo taylor-made.", url: "trilhas-formativas" },
    { title: "TEAM BUILDING", description: "sabia que dá pra aprender agilidade na cozinha?", url: "team-buildings" },
    { title: "CONSULTORIA", description: "porque mudanças culturais doem." },
    { title: "PROJETOS EDUCACIONAIS", description: "pra estimular a inteligência coletiva." },
    { title: "M/TALKS", description: "as palestras da Mastertech." },
  ];

  return (
    <div className="SetList">
      <div className="container-setlist">
        {/* Animação do título */}
        <motion.h2
          className="setlist-title"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={titleVariants}
        >
          O setlist
        </motion.h2>

        <div className="button-list">
          {items.map((item, index) => (
            <motion.div
              className="button-item"
              key={index}
              custom={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={itemVariants}
              onClick={() => navigate(item.url)}
            >
              <span className="title">{item.title}</span>
              <span className="dots"></span>
              <span className="description">{item.description}</span>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SetList;

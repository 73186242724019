import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import like from "../../assets/images/trainingdetails/like.png";
import ppnp from "../../assets/images/trainingdetails/ppnp.png";
import Loading from '../../components/common/Loading/Loading';
import { replaceWithLineBreaks } from '../../utils/formating';
import './TrainingDetails.scss';
import { traininglist } from './trainingList';

const TrainingDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contentTraining, setContentTraining] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    empresa: "",
    celular: "",
    email: "",
    mensagem: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome completo é obrigatório.";
    if (!formData.empresa.trim()) newErrors.empresa = "Empresa é obrigatória.";
    if (!formData.celular.trim()) newErrors.celular = "Celular é obrigatório.";
    if (!formData.email.trim()) {
      newErrors.email = "E-mail é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "E-mail inválido.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      alert("Formulário enviado com sucesso!");
      setFormData({
        nome: "",
        empresa: "",
        celular: "",
        email: "",
      });
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  console.log(contentTraining)
  // Simulando uma API local
  const fetchTrainingDetails = async (id) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const result = traininglist.find((training) => training.id === parseInt(id));
      setTimeout(() => {
        if (result) {
          resolve(result);
          setLoading(false);
        } else {
          setLoading(false);
          reject(new Error("Detalhes do training não encontrados."));
        }
      }, 500);
    });
  };

  useEffect(() => {
    const getTrainingDetails = async () => {
      try {
        const result = await fetchTrainingDetails(id);
        setContentTraining(result);
      } catch (error) {
        console.error(error.message);
        navigate("/trilhas-formativas");
      }
    };

    getTrainingDetails();
  }, [id, navigate]);

  const data = {
    id: 1,
    title: "programação para\n não programadores",
    subtitle: "uma introdução à programação\n para grupos não técnicos.",
    description: "Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.",
    image: ppnp,
  }

  return (
    <>
      <div className='TrainingDetails'>
        <div className="container-training-details">
          <motion.div
            className="header-details"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            <div className="content">
              <h1 dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentTraining?.title) }} />
              <p className='subtitle' dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentTraining?.subtitle) }} />
              <p className='description'>{contentTraining?.description}</p>
            </div>
            <div className="header-img">
              {contentTraining?.image && <img src={contentTraining?.image} alt={contentTraining?.title} />}
            </div>
          </motion.div>

          <div className="learning-section">
            <h2 className="learning-section__title">O QUE VOCÊ VAI APRENDER</h2>
            <div className="learning-section__content">
              <motion.div
                className="learning-section__item learning-section__item--left"
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <div className="learning-section__item-learning-box">
                  <h3 className="learning-section__item-title">
                    Fundamentos <br /> <span>do ChatGPT</span>
                  </h3>
                  <p className="learning-section__item-description">
                    {contentTraining?.fundamentals[0].describe}
                  </p>
                </div>
                <div className="dot" />
              </motion.div>

              <motion.div
                className="learning-section__item learning-section__item--right"
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                viewport={{ once: true }}
              >
                <div className="learning-section__item-learning-box">
                  <h3 className="learning-section__item-title">
                    Uso avançado <br /> <span>do ChatGPT</span>
                  </h3>
                  <p className="learning-section__item-description">
                    {contentTraining?.fundamentals[1].describe}
                  </p>
                </div>
                <div className="dot" />
              </motion.div>

              <motion.div
                className="learning-section__item learning-section__item--left"
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                viewport={{ once: true }}
              >
                <div className="learning-section__item-learning-box">
                  <h3 className="learning-section__item-title">
                    Exploração <br /> <span>de possibilidades</span>
                  </h3>
                  <p className="learning-section__item-description">
                    {contentTraining?.fundamentals[2].describe}
                  </p>
                </div>
                <div className="dot" />
              </motion.div>
              <motion.p
                className="text-flutuant"
                initial={{
                  x: "-200px",
                  opacity: 0,
                  transform: "matrix(0, -1, 1, 0, 0, 0)"
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transform: "matrix(0, -1, 1, 0, 0, 0)"
                }}
                exit={{
                  x: "-20vw",
                  opacity: 0,
                  transform: "matrix(0, -1, 1, 0, 0, 0)"
                }}
                transition={{ duration: 1.5, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                gen IA
              </motion.p>

            </div>
          </div>

          <div className="duration-section">
            <div className="duration">
              <motion.p
                className="duration-title"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
              >
                DURAÇÃO
              </motion.p>
              <motion.p
                className="duration-content"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1.2 }}
                viewport={{ once: true }}
              >
                {contentTraining?.duration}
              </motion.p>
            </div>

            <div className="duration">
              <motion.p
                className="duration-title"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1.4 }}
                viewport={{ once: true }}
              >
                EXPERIÊNCIA
              </motion.p>
              <motion.p
                className="duration-content"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1.6 }}
                viewport={{ once: true }}
              >
                {contentTraining?.experience}
              </motion.p>
            </div>

            <div className="duration">
              <motion.p
                className="duration-title"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1.8 }}
                viewport={{ once: true }}
              >
                PARTICIPANTES
              </motion.p>
              <motion.p
                className="duration-content"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 2 }}
                viewport={{ once: true }}
              >
                {contentTraining?.participants}
              </motion.p>
            </div>
          </div>
        </div>
      </div>

      <motion.div
        className="ContactPDF"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 2.2 }}
        viewport={{ once: true }}
      >
        <div className="container-contactpdf">
          <div className="info-contact">
            <img src={like} alt="Gostou?" />
            <p>Preencha os campos ao lado e receba a apresentação do <strong>{contentTraining?.minTitle}</strong> direto no seu e-mail :)</p>
          </div>

          <motion.div
            className="container-contact"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 2.4 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <form className="custom-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <motion.div
                  className={`form-group ${errors.nome ? "error" : ""}`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 2.6 }}
                  viewport={{ once: true }}
                >
                  <input
                    type="text"
                    name="nome"
                    placeholder="NOME COMPLETO"
                    value={formData.nome}
                    onChange={handleChange}
                  />
                  {errors.nome && <span className="error">{errors.nome}</span>}
                </motion.div>

                <motion.div
                  className={`form-group ${errors.email ? "error" : ""}`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 2.8 }}
                  viewport={{ once: true }}
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="SEU E-MAIL COMERCIAL"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </motion.div>

                <motion.div
                  className={`form-group ${errors.empresa ? "error" : ""}`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 3 }}
                  viewport={{ once: true }}
                >
                  <input
                    type="text"
                    name="empresa"
                    placeholder="EMPRESA"
                    value={formData.empresa}
                    onChange={handleChange}
                  />
                  {errors.empresa && <span className="error">{errors.empresa}</span>}
                </motion.div>
              </div>

              <div className="form-row">
                <motion.div
                  className={`form-group ${errors.celular ? "error" : ""}`}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 3.1 }}
                  viewport={{ once: true }}
                >
                  <input
                    type="text"
                    name="celular"
                    placeholder="CELULAR"
                    value={formData.celular}
                    onChange={handleChange}
                  />
                  {errors.celular && <span className="error">{errors.celular}</span>}
                </motion.div>
              </div>

              <div className="form-row">
                <motion.button
                  type="submit"
                  className="btn-enviar"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 3.2 }}
                  viewport={{ once: true }}
                >
                  BAIXAR PDF
                </motion.button>
              </div>
            </form>
          </motion.div>
        </div>
      </motion.div>
      <Loading active={loading} />

    </>
  );
}

export default TrainingDetails;

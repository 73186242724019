import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="Footer">
      <motion.div
        className="footer-content"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        viewport={{ once: true }}
      >
        <div className="footer-left">
          <motion.div
            className="footer-links"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut", delay: 0.3 }}
            viewport={{ once: true }}
          >
            <div className="footer-logo">
              <motion.h1
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                MASTERTECH
              </motion.h1>
            </div>
            <ul className='button-list'>
              <li onClick={() => {
                navigate('mastertech-who')
              }}>MASTERTECH WHO?</li>
              <li onClick={() => {
                navigate('cases')
              }}>CASES</li>
            </ul>
            <ul className='button-list'>
              <li onClick={() => {
                navigate('trilhas-formativas')
              }}>TRILHAS FORMATIVAS</li>
              <li onClick={() => {
                navigate('team-buildings')
              }}>TEAM BUILDING</li>
              <li>CONSULTORIA</li>
              <li>PROJETOS EDUCACIONAIS</li>
              <li>M/TALKS</li>
            </ul>
            <ul>
              <li>Av. Brigadeiro Luis Antônio, 2696</li>
              <li>Bela Vista</li>
              <li>CEP 01402-000</li>
              <li>TEL +55 11 91952-2455</li>
            </ul>
          </motion.div>
          <motion.div
            className="footer-logos"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeInOut", delay: 0.5 }}
            viewport={{ once: true }}
          >
            <span className="ic--baseline-whatsapp" />
            <span className="mdi--instagram" />
            <span className="mingcute--youtube-fill" />
            <span className="ri--linkedin-fill" />
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="footer-bottom"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeInOut", delay: 0.7 }}
        viewport={{ once: true }}
      >
        <p>Políticas de privacidade e portal do titular</p>
        <p>Mastertech 2024. Todos os direitos reservados.</p>
      </motion.div>
    </footer>
  );
};

export default Footer;

import React from 'react'
import Banner from '../../components/ui/Banner/Banner'
import ContactHome from '../../components/ui/ContactHome/ContactHome'
import FeedCases from '../../components/ui/FeedCases/FeedCases'
import MDailyHome from '../../components/ui/MDailyHome/MDailyHome'
import SetList from '../../components/ui/SetList/SetList'
import './Home.scss'

const Home = ({ setInvertedNavbar }) => {
  setInvertedNavbar(true)
  return (
    <div className='Home'>
      <Banner />
      <SetList />
      <FeedCases />
      <ContactHome />
      <MDailyHome />
    </div>
  )
}

export default Home
